<template>
    <div class="contact-details-main">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="container-fluid">
            <div class="contact-details">
                <div class="container-fluid">
                    <div class="notices-heading">
                        <p>Brand Notices</p>
                        <button @click="showAddnoticeInput()" id="add-new-notice">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 455 455" style="enable-background:new 0 0 455 455;" xml:space="preserve">
                                <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5   455,242.5 "/>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                            </svg>
                            ADD NEW NOTICE
                        </button>
                    </div>
                </div>
                <div class="border-bottom">
                </div>
                <div class="notice-area">
                    <div class="container-fluid">

                        <div v-for="(notice,index) in notices_list" :key="index" class="notice-1">
                            <h4>Notice {{index+1}}</h4>
                            <div class="notice-1-inner">
                                <div id="edit" >
                                    {{notice}}
                                </div>
                                <div class="edit">
                                    <div class="dropdown-icon">
                                        <button class="dropbtn">
                                        <span class="text-icon">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </span>
                                        </button>
                                        <div class="dropdown-content">
                                            <div class="arrow-up"></div>
                                            <a href="#"  data-toggle="modal" :data-target="'#exampleModal'+index" >
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"> </i>Edit</a>
                                            <hr>
                                            <div class="del">
                                                  <a href="#" data-toggle="modal" :data-target="'#exampleModal18'+index"
                                                    ><i class="fa fa-trash-o" aria-hidden="true"> </i>Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- delete modal -->
                            <div  class="modal fade" 
                            :id="'exampleModal18'+index"
                            tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title  mx-auto" id="exampleModalLabel18">Delete Notice</h5>
                                        </div>
                                        <div class="modal-body">
                                            <p>Are you sure you want to delete this notice? </p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                            <button data-dismiss="modal" @click="delete_notice(notice,index)" type="button" class="btn btn-primary">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- edit modal -->
                            <div class="modal fade" :id="'exampleModal'+index" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title mx-auto" id="exampleModalLabel">Edit Notice</h5>
                                        </div>
                                        <div class="modal-body">
                                            <form>
                                                <div class="form-row">
                                                    <div class="col">
                                                        <input type="text" class="form-control"   :value="notice"  @keydown="addTag($event)">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                            <button type="button" class="btn btn-primary" data-dismiss="modal"  @click="update_notice(notice,index)">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            

                        </div>

                    </div>
                    <div v-show="addNewNoticeInput" class="new-notice">
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">New Notice</label>
                            <textarea maxlength="200" class="form-control" id="exampleFormControlTextarea2" rows="5" placeholder="..." v-model="addNewNotice">
                            </textarea>
                            <div id="the-count">
                                <span id="current">0</span>
                                <span id="maximum">/ 200</span>
                            </div>
                        </div>
                        <div >
                            <button class="notice-cancel-button" @click="discard_add_notice()" >Cancel</button>
                            <button  class="notice-save-button" @click="save_add_notice()" >Save</button>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'

    export default {
        name: 'BrandsNotice',
        data () {
            return {
                loading:false,
                notices_list:[],
                addNewNoticeInput:false,
                addNewNotice:'',
                errors:[],
                notice_value:'',

           
            }
        },
        methods: {
            showAddnoticeInput(){
                this.addNewNoticeInput=true
            },

            async save_add_notice(){
                this.errors=[]
                this.addNewNoticeInput=false
                if (this.validate()) {
                    this.loading= true;
                    if(!this.notices_list.includes(this.addNewNotice)){
                        this.notices_list.push(this.addNewNotice)
                    }


                    try{
                        var brand_id = this.$store.state.brand
                        const data =  { 
                            "notices": this.notices_list,
                            'brand':this.$store.state.brand
                        };
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                        const response =  await axios.patch(api_url,data,{headers})
                        console.log('response',response)
                        this.loading= false;
                        await this.$toasted.success("Notice added Successfully");
                    }
                    catch (e) {
                        this.loading = false;
                        console.log('error is',e)
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }
                }
            },

            async delete_notice(notice,index){
                if(this.notices_list.includes(notice)){
                    this.notices_list.splice(index,1)
                }
                try{
                    this.loading= true;
                    var brand_id = this.$store.state.brand
                    const data =  { 
                        "notices": this.notices_list,
                        'brand':this.$store.state.brand
                    };
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                    const response =  await axios.patch(api_url,data,{headers})
                    console.log('response',response)
                    this.loading= false;
                    await this.$toasted.success("Notice Deleted Successfully");
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }




              


            },

            addTag(event){
                this.notice_value=event.target.value

            },

            async update_notice(notice,index){
                this.notices_list[index]=this.notice_value
                try{
                    this.loading= true;
                    var brand_id = this.$store.state.brand
                    const data =  { 
                        "notices": this.notices_list,
                        'brand':this.$store.state.brand
                    };
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                    const response =  await axios.patch(api_url,data,{headers})
                    console.log('response',response)
                    this.loading= false;
                    await this.$toasted.success("Notice Edited Successfully");
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }

           
            },

            discard_add_notice(){
                this.addNewNotice=''
            },










            validate(){
                if ((!this.addNewNotice) || (this.addNewNotice<=0)) {
                    this.errors.push("Please provide Notice!")
                    this.$toasted.error('Please provide Notice!');
                }
                if (!this.errors.length) {
                    return true;
                }
                 
                 
            },



       
        },
        mounted(){

            

          

        },
        async created(){
            try{
                this.loading= true;
                var i;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                var brand_id = this.$store.state.brand
                var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                const response =  await axios.get(api_url,{headers})
                this.loading = false;
                this.notices_list=response.data.notices
                console.log('------------notices data--------------',this.notices_list);

            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }

        }
    }

</script>


<style>

</style>